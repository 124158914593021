<template>
  <div
    :class="`font-sans antialiased w-full  ${
      TEMA.navbar.fixed ? ' top-0 z-30 fixed ' : ''
    }`"
  >
    <nav
      :class="`flex items-center justify-between flex-wrap bg-teal p-6 ${TEMA.navbar.bgColor}`"
    >
      <div
        :class="`flex items-center flex-no-shrink mr-6 ${TEMA.navbar.textTitleColor} `"
      >
        <span
          :class="`font-semibold text-2xl tracking-tight ${TEMA.navbar.textTitleSize} `"
          >{{ APP_NAME }}</span
        >
      </div>
      <div class="block sm:hidden">
        <button
          @click="toggle"
          class="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white"
        >
          <svg
            :class="`h-3 w-3 ${TEMA.navbar.mobileMenuIconColor}`"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="open ? 'block' : 'hidden'"
        class="w-full items-end flex-grow sm:flex sm:items-center sm:w-auto"
      >
        <div class="text-sm sm:flex-grow justify-end items-end">
          <router-link
            :class="`no-underline block mt-4 md:float-right sm:inline-block sm:mt-0 text-teal-lighter mr-4 
                text-base
               ${
                 currentRoute == item.route ? 'text-black' : 'text-white'
               }  hover:${
              currentRoute == item.route ? 'text-white' : 'text-black'
            }`"
            v-for="(item, idx) in ROUTES"
            :key="idx"
            :to="item.route"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { APP_NAME, TEMA, ROUTES } from './../config'
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const currentRoute = ref('/')
    const routes = useRouter()

    let open = ref(false)

    const toggle = () => {
      open.value = !open.value
    }

    watchEffect(() => {
      currentRoute.value = routes.currentRoute.value.path
    })

    return {
      scroll,
      APP_NAME,
      toggle,
      open,
      ROUTES,
      TEMA,
      currentRoute,
    }
  },
}
</script>

<style></style>
