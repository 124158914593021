<template>
  <component :is="layout">
    <h1>{{ layout }}</h1>
    <slot />
  </component>
</template>

<script>
import FullLayout from './FullLayout'
import { markRaw, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'AppLayout',
  setup() {
    const layout = markRaw(FullLayout)
    const route = useRoute()
    const fullLayout = ['/login']
    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = await import(`@/layouts/${meta.layout}.vue`)

          layout.value = component
        } catch (e) {
          layout.value = FullLayout
        }
        console.log(layout.value)
      },
      { immediate: true }
    )
    return { layout }
  },
}
</script>
