<template>
  <router-view />
</template>

<script>
import NavBarVue from './components/NavBar.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    NavBarVue,
  },
  setup() {
    const routes = useRouter()
    const routesFullLayout = ['/']

    return {
      routes,
      currentRoute: routes.currentRoute.value.path,
      routesFullLayout,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  height: 100vh;
  min-height: 100vh;
}
</style>
