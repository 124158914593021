import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'ant-design-vue/dist/antd.css'
import './assets/css/global.css'
import './index.css'
import router from './router'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import SidebarLayout from './layouts/SidebarLayout'
import AppLayout from '@/layouts/AppLayout.vue'
import FullLayout from '@/layouts/FullLayout.vue'

const app = createApp(App)

app.component('SidebarLayout', SidebarLayout)
app.component('AppLayout', AppLayout)
app.component('FullLayout', FullLayout)

app.config.productionTip = true
app.config.globalProperties.axios = axios
app.prototype
app.use(Antd)
app.use(VueClipboard)
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')
