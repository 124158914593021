<template>
  <div class="flex flex-no-wrap bg-gray-200 h-screen w-full">
    <div class="w-64 sticky px-8 top-0 bg-gray-800 shadow h-screen">
      <div class="h-28 w-full pt-10 flex flex-wrap items-center text-center">
        <p class="text-center text-white font-semibold text-base">
          ANC - Transparência
        </p>
      </div>
      <ul class="mt-12">
        <router-link to="/arquivos">
          <li
            :class="
              `flex w-full justify-between ${
                currentRoute == '/arquivos' ? 'text-gray-100' : 'text-gray-600'
              } hover:text-gray-500 cursor-pointer items-center mb-6`
            "
          >
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-stack"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="12 4 4 8 12 12 20 8 12 4" />
                <polyline points="4 12 12 16 20 12" />
                <polyline points="4 16 12 20 20 16" />
              </svg>
              <span class="text-sm ml-2">Arquivos</span>
            </div>
          </li>
        </router-link>
        <li
          @click="logout"
          class="flex w-full justify-between text-gray-600 hover:text-gray-500 cursor-pointer items-center mb-6`"
        >
          <div class="flex items-center">
            <span class="text-sm ml-2">Sair</span>
          </div>
        </li>
      </ul>
    </div>

    <!-- Sidebar ends -->
    <!-- Remove class [ h-64 ] when adding a card block -->
    <div class="px-8 mx-auto w-full h-screen overflow-y-scroll">
      <!-- Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border -->
      <div class="w-full h-full">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getUser } from '../store/useAuth'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const moved = ref(true)
    const routes = useRouter()

    const user = getUser()

    function logout() {
      localStorage.removeItem('user')

      window.location.href = '/'
    }

    return {
      logout,
      moved,
      currentRoute: routes.currentRoute.value.path,
      user,
    }
  },
}
</script>
