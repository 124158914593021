import api from './api'
import { message } from 'ant-design-vue'

const API = api(true)

export const updateData = async (
  route,
  data,
  debug = false,
  secure = false
) => {
  try {
    const res = await API.put(route, data)
    if (res.status === 200) {
      message.success('Atualização realizada com sucesso')
    } else {
      if (res.data.err != undefined) {
        message.error(res.data.err)
      } else {
        message.error(
          'Verifique se preencheu os campos corretamente e tente novamente'
        )
      }
    }
  } catch (err) {
    if (debug) console.log(err)
    message.error('500 - Ocorreu um erro, tente novamente mais tarde')
  }
}

export const deleteData = async (route, debug = false) => {
  try {
    const res = await API.delete(route)
    if (res.data == 1) {
      message.success('Exclusão realizada com sucesso')
    } else {
      message.error(
        'Ops, ocorreu um erro desconhecido, tente novamente mais tarde'
      )
    }
  } catch (err) {
    if (debug) console.log(err)
    message.error('500 - Ocorreu um erro, tente novamente mais tarde')
  }
}

export const createData = async (route, data, debug = false) => {
  try {
    const res = await API.post(route, data)
    if (res.data.id != undefined) {
      message.success('Cadastro realizado com sucesso')
      return res.data
    } else {
      if (res.data.err != undefined) {
        message.error(res.data.err)
      } else {
        message.error(
          'Verifique se preencheu os campos corretamente e tente novamente'
        )
      }
      return null
    }
  } catch (err) {
    if (debug) console.log(err)
    message.error('500 - Ocorreu um erro, tente novamente mais tarde')
    return null
  }
}

export const getData = async (route, debug = false) => {
  try {
    const res = await API.get(route)
    if (res.status == 200) {
      return res.data
    } else {
      message.error(
        'Verifique se preencheu os campos corretamente e tente novamente'
      )
      return null
    }
  } catch (err) {
    // if (debug) console.log(err)
    console.log(err)
    message.error(
      '500 - Ocorreu um erro ao recuperar os dados, tente novamente mais tarde'
    )
    return null
  }
}
