<template>
  <SidebarLayout>
    <div class="container mx-auto arquivos">
      <div v-if="loading" class="center-content justify-center w-full">
        <a-spin tip="Carregando dados, aguarde..."> </a-spin>
      </div>
      <div v-if="!loading" class="w-full mb-10">
        <div>
          <div class="flex flex-wrap mt-10 justify-between mb-10 w-full">
            <div class="flex flex-wrap justify-between">
              <div class="flex flex-wrap">
                <h1 class="font-bold text-3xl">ARQUIVOS</h1>
              </div>
            </div>
            <div>
              <!-- <ModalEditArquivoLote
              v-if="selectedFiles.length > 0"
              :arquivos="selectedFiles"
              :subgrupos="subgrupos"
              @update="fetchArquivos"
              :key="`edit-lote-${selectedFiles.length}`"
            /> -->
            </div>
          </div>
          <div class="w-full bg-white rounded-sm shadow-md pb-5 pl-2 pr-5">
            <a-table
              :columns="columns"
              :data-source="arquivos"
              :row-selection="rowSelection"
            >
              <template
                #filterDropdown="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              >
                <div style="padding: 8px">
                  <a-input
                    ref="searchInput"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    <template #icon><SearchOutlined /></template>
                    Pesquisar
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="handleReset(clearFilters)"
                  >
                    Redefinir
                  </a-button>
                </div>
              </template>

              <template #descricao="{ record }">
                <span v-if="!record.subgrupo_detalhado">
                  {{ getFileDescription(record) }}
                </span>

                <a-tag
                  v-if="record.subgrupo_detalhado && record.raw != null"
                  color="green"
                  >ARQUIVO DETALHADO</a-tag
                >
                <a-tag
                  v-if="record.subgrupo_detalhado && record.raw == null"
                  color="red"
                  >SEM DETALHES</a-tag
                >
              </template>

              <template #status="{ record }">
                <a-tag
                  v-if="record.id_subgrupo != 0"
                  :color="record.status != 1 ? 'red' : 'green'"
                >
                  {{ record.status != 1 ? 'Inativo' : 'Ativo' }}
                </a-tag>
                <a-tag v-if="record.id_subgrupo == 0" color="red">
                  Sem grupo
                </a-tag>
              </template>
              <template #action="{ record }">
                <div class="flex item-center justify-center">
                  <a-tooltip
                    placement="topLeft"
                    title="Ver arquivo"
                    class="mr-2"
                  >
                    <div
                      class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer"
                    >
                      <svg
                        @click="openLink(record.url)"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    </div>
                  </a-tooltip>
                  <a-tooltip placement="topLeft" title="Editar" class="mr-2">
                    <div
                      class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer"
                    >
                      <svg
                        @click="editFile(record)"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </div>
                  </a-tooltip>
                </div>
              </template>
            </a-table>
          </div>
        </div>
        <a-modal
          v-model:visible="modalStateEditFile"
          :title="'Editar arquivo: ' + currentFile.titulo"
          @ok="updateFile"
          width="1200px"
        >
          <div v-if="loading" class="center-content justify-center w-full">
            <a-spin tip="Atualizando, aguarde..."> </a-spin>
          </div>
          <div v-else>
            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold">Titulo</label>
              <a-input
                class="w-full"
                v-model:value="currentFile.titulo"
                placeholder="Nome"
              ></a-input>
            </div>
            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold">Data de Publicação</label>
              <a-input
                class="w-full"
                type="date"
                v-model:value="currentFile.data_publicacao"
                placeholder="Ex: 2021-05-05"
              ></a-input>
            </div>

            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold">Subgrupo</label>
              <a-select
                class="w-full"
                v-model:value="currentFile.id_subgrupo"
                placeholder="Subgrupo"
              >
                <a-select-option
                  v-for="(item, idx) in subgrupos"
                  :key="idx"
                  :value="item.id"
                >
                  {{ item.titulo }}
                </a-select-option>
              </a-select>
            </div>

            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold"
                >Descrição (Este campo é comum entre os arquivos de mesmo
                nome)</label
              >
              <a-input
                v-if="!currentFile.subgrupo_detalhado"
                class="w-full"
                v-model:value="currentFile.descricao"
                placeholder="Ex: ATA DE ..."
              ></a-input>

              <table
                v-if="currentFile.subgrupo_detalhado"
                class="w-full border-2 border-coolGray-700 mt-4"
              >
                <tr class="w-full bg-gray-700 p-2 text-white">
                  <th
                    v-for="row in currentFile.subgrupo_descricao.split(';')"
                    class="p-2"
                  >
                    {{ row }}
                  </th>
                  <th>Ações</th>
                </tr>

                <tr class="w-full">
                  <td
                    v-for="row in currentFile.subgrupo_descricao.split(';')"
                    class="p-2"
                  >
                    <a-input
                      v-model:value="currentRow[row.toString()]"
                      class="w-full"
                    ></a-input>
                  </td>
                  <td class="pr-2">
                    <a-button
                      type="primary"
                      class="pt-0"
                      block
                      @click="addRowTable"
                      >+</a-button
                    >
                  </td>
                </tr>

                <tr v-for="(row, idx) in rows" class="w-full">
                  <td
                    v-for="prop in currentFile.subgrupo_descricao.split(';')"
                    class="w-full p-2"
                  >
                    {{ row[prop.toString()] }}
                  </td>
                  <td class="p-2">
                    <a-button
                      type="danger"
                      class="mt-2"
                      block
                      @click="deleteFile(idx)"
                    >
                      -</a-button
                    >
                  </td>
                </tr>
                <!-- <td>
               
              </td> -->
              </table>
            </div>

            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold">Extenção</label>
              <a-select
                class="w-full"
                v-model:value="currentFile.extensao"
                placeholder="Extenção"
              >
                <a-select-option value="PDF"> PDF </a-select-option>
                <a-select-option value="DOC"> DOC </a-select-option>
                <a-select-option value="TXT"> TXT </a-select-option>
              </a-select>
            </div>
            <div class="w-full px-3 mb-4 text-left">
              <label class="font-semibold">Situação</label>
              <a-select
                class="w-full"
                v-model:value="currentFile.status"
                placeholder="Situação"
              >
                <a-select-option value="1"> Ativo </a-select-option>
                <a-select-option value="0"> Inativo </a-select-option>
              </a-select>
            </div>
          </div>
        </a-modal>
      </div>
    </div>
  </SidebarLayout>
</template>

<script>
import { ref, onMounted } from 'vue'
import { getData, updateData } from '../api/http'
import { getRefValue } from '../config'
import { ArquivosColumns } from './arquivos/TableArquivos'
// import ModalEditArquivoLote from './arquivos/ModalEditArquivoLote.vue'
import { stringToArray } from '../config'
import {
  SmileOutlined,
  DownOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue'
import { getUser } from '../store/useAuth'
import SidebarLayout from '../layouts/SidebarLayout.vue'
export default {
  components: {
    SmileOutlined,
    DownOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    UnorderedListOutlined,
    SidebarLayout,
  },
  setup() {
    const loading = ref(false)
    const arquivos = ref(null)
    const subgrupos = ref([])
    const selectedFiles = ref([])
    const searchInput = ref('')
    const currentFile = ref({})
    const modalStateEditFile = ref(false)
    const currentRow = ref({})
    const user = ref(null)

    const rows = ref([])
    const qtdRows = ref(1)

    const deleteFile = (idx) => {
      qtdRows.value--
      rows.value.splice(idx, 1)
    }

    const addRowTable = () => {
      rows.value.push({ ...currentRow.value, id: qtdRows })
      currentRow.value = {}
      qtdRows.value++
    }

    const makeTableDetail = () => {
      let table = `<table class="mt-2 w-full border-2 ">
                      <tr class="w-full bg-gray-600 p-2 text-white">`

      for (let row of currentFile.value.subgrupo_descricao.split(';')) {
        table += `<th class="p-2">${row}</th>`
      }
      let count = 0
      for (let row of rows.value) {
        table += `</tr><tr class="w-full ${
          count % 2 != 0 ? 'bg-gray-200' : ''
        }">`
        for (let prop of currentFile.value.subgrupo_descricao.split(';')) {
          table += `<td  class="p-2"> ${row[prop.toString()]} </td>`
        }
        table += `</tr>`
        count++
      }

      table += `</table>`

      return table
    }

    const fetchArquivos = async () => {
      loading.value = true
      arquivos.value = await getData(`arquivos`)
      loading.value = false
    }

    const updateFile = async () => {
      if (currentFile.value.subgrupo_detalhado) {
        currentFile.value.descricao = makeTableDetail()
      }
      delete currentFile.value.updated_at
      delete currentFile.value.created_at
      delete currentFile.value.id_autarquia
      delete currentFile.value.subgrupo_descricao
      delete currentFile.value.subgrupo_titulo
      delete currentFile.value.subgrupo_detalhado
      delete currentFile.value.grupo_titulo
      if (rows.value.length > 0) {
        currentFile.value.raw = JSON.stringify(rows.value)
      } else {
        console.log(rows.value)
      }
      loading.value = true
      await updateData(`arquivos/` + currentFile.value.id, currentFile.value)
      modalStateEditFile.value = false
      loading.value = false
      fetchArquivos()
    }

    const fetchSubgrupos = async () => {
      loading.value = true
      subgrupos.value = await getData(`subgrupos`)
      loading.value = false
    }

    const getFileDescription = (file) => {
      const date = new Date(file.data_publicacao)
      let descricao =
        file.descricao == null || file.descricao == undefined
          ? file.id_subgrupo == 0
            ? ''
            : file.subgrupo_descricao
          : file.descricao
      if (descricao == null || descricao == undefined) return ''
      descricao = descricao.replace('[ano]', date.getFullYear())
      descricao = descricao.replace('[mes]', date.getMonth())
      descricao = descricao.replace('[dia]', date.getDay())
      return descricao
    }

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedFiles.value = selectedRows
      },
      onSelect: (record, selected, selectedRows) => {
        selectedFiles.value = selectedRows
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        selectedFiles.value = selectedRows
      },
    }

    onMounted(async () => {
      user.value = getUser()
      await fetchArquivos()
      await fetchSubgrupos()
    })

    const isFullLink = (link) => {
      if (link == null || link == undefined) return false
      return (
        link.includes('http://') ||
        link.includes('https://') ||
        link.includes('www.')
      )
    }

    const openLink = (link) => {
      if (!isFullLink(link)) {
        link = user.value.autarquia.url + link
      }
      window.open(link, '_blank')
    }

    const state = ref({
      searchText: '',
      searchedColumn: '',
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.value.searchText = selectedKeys[0]
      state.value.searchedColumn = dataIndex
    }

    const handleReset = (clearFilters) => {
      clearFilters()
      state.value.searchText = ''
    }

    const editFile = (arquivo) => {
      currentFile.value = arquivo
      if (arquivo.raw != undefined) {
        rows.value = stringToArray(arquivo.raw)
      }
      modalStateEditFile.value = true
    }

    return {
      arquivos,
      loading,
      getFileDescription,
      subgrupos,
      columns: ArquivosColumns,
      selectedFiles,
      rowSelection,
      fetchArquivos,
      searchInput,
      searchText: '',
      openLink,
      isFullLink,
      getRefValue,
      handleSearch,
      handleReset,
      rows,
      qtdRows,
      deleteFile,
      currentRow,
      addRowTable,
      makeTableDetail,
      editFile,
      modalStateEditFile,
      currentFile,
      updateFile,
    }
  },
}
</script>
