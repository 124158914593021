import { createWebHistory, createRouter } from 'vue-router'
import ArquivosPage from '../pages/arquivos.vue'
import LoginPage from './../pages/login.vue'
import AuthMiddleware from './../middlewares/auth.middleware.js'
import NotFound from '@/pages/NotFound'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
    meta: {
      layout: 'FullLayout',
    },
  },
  {
    path: '/arquivos',
    name: 'Arquivos',
    component: ArquivosPage,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('user')) {
        console.log('No token found')
        return router.push({ name: 'Login' })
      }
      console.log('Token found')
      return next()
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('user')) {
        console.log('No token found')
        return router.push({ name: 'Login' })
      }
      console.log('Token found')
      return next()
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

export default router
