import axios from 'axios'
import { API_URL } from './../config'

export default (secured = true) => {
  if (secured) {
    const user = JSON.parse(localStorage.getItem('user'))
    const axiosInstance = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `bearer ${user != null ? user.token : ''}` },
    })

    axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    return axiosInstance
  } else {
    return axios.create({
      baseURL: API_URL,
    })
  }
}

// export default axios.create({
//   baseURL: API_URL,
// });
