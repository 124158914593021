export const getUser = () => {
  const data = window.localStorage.getItem('user')
  if (data != null && data != undefined) {
    const user = JSON.parse(data)
    if (user != null) {
      return user
    }
  }
  return null
}
