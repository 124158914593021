<template>
  <section class="text-gray-600 body-font h-screen bg-white">
    <div class="container px-5 py-24 mx-auto align-middle">
      <div class="w-full md:w-1/3 rounded-lg p-8 mt-10 inline-block">
        <h2
          class="text-xl font-semibold text-gray-800 uppercase title-font mb-5"
        >
          Dashboard - Transparência
        </h2>
        <div v-if="loading" class="center-content justify-center w-full">
          <a-spin tip="Fazendo login..."> </a-spin>
        </div>
        <div v-else>
          <div class="relative mb-4">
            <label
              for="email"
              class="leading-7 text-lg text-gray-800 float-left"
              >Email</label
            >
            <input
              v-model="form.email"
              type="email"
              id="email"
              name="email"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label
              for="password"
              class="leading-7 text-lg text-gray-800 float-left"
              >Senha</label
            >
            <input
              v-model="form.password"
              type="password"
              id="password"
              name="password"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            @click="login"
            class="text-white w-full bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
          >
            Fazer login
          </button>
        </div>
      </div>
      <a target="_blank" href="https://anctecnologia.com.br" class="bottom-0">
        <h4 class="text-gray-600 text-sm no-underline">
          ANC Tecnologia da Informação
        </h4></a
      >
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import api from './../api/api'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
export default {
  setup() {
    const routes = useRouter()
    const form = ref({})
    const loading = ref(false)

    const API = api(false)

    const login = async () => {
      try {
        loading.value = true
        const res = await API.post('auth/signin', form.value)
        if (res.data.token != undefined) {
          await localStorage.setItem('user', JSON.stringify(res.data))
          // await routes.push('/arquivos')
          window.location.href = '/arquivos'
        } else {
          notification.open({
            message: 'Ops',
            description:
              'Ocorreu um erro, verifique os dados e tente novamente',
          })
        }
      } catch (err) {
        console.error(err)
        notification.open({
          message: 'Ops',
          description:
            '500 - Ocorreu um erro, verifique os dados e tente novamente',
        })
      } finally {
        loading.value = false
      }
    }
    return {
      loading,
      form,
      login,
    }
  },
}
</script>
