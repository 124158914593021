export const ArquivosColumns = [
  {
    dataIndex: 'titulo',
    key: 'titulo',
    title: 'Nome',
    slots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) => {
      let key1 = record.titulo
      if (key1 != null) key1 = key1.toLowerCase()
      if (value != null) value = value.toLowerCase()
      if (key1 == null) return false
      return key1.includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          console.log(searchInput.value)
          searchInput.value.focus()
        }, 0)
      }
    },
    sorter: (a, b) => a.titulo > b.titulo,
    sortDirections: ['descend'],
  },
  {
    title: 'Extenção',
    dataIndex: 'extensao',
    key: 'extensao',
    onFilter: (value, record) =>
      record.extensao.toLowerCase().includes(value.toLowerCase()),
    filters: [
      {
        text: 'PDF',
        value: 'PDF',
      },
      {
        text: 'TXT',
        value: 'TXT',
      },
      {
        text: 'DOC',
        value: 'DOC',
      },
      {
        text: 'XLS',
        value: 'XLS',
      },
      {
        text: 'PPT',
        value: 'PPT',
      },
    ],
  },
  {
    title: 'Grupo',
    dataIndex: 'grupo_titulo',
    key: 'id_grupo',
    slots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'Grupo',
    },
    onFilter: (value, record) => {
      let key1 = record.grupo_titulo
      if (key1 != null) key1 = key1.toLowerCase()
      if (value != null) value = value.toLowerCase()
      if (key1 == null) return false
      return key1.includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          console.log(searchInput.value)
          searchInput.value.focus()
        }, 0)
      }
    },
    sorter: (a, b) => a.grupo_titulo > b.grupo_titulo,
    sortDirections: ['descend'],
  },
  {
    title: 'Subgrupo',
    dataIndex: 'subgrupo_titulo',
    key: 'id_subgrupo',
    slots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'Subgrupo',
    },
    onFilter: (value, record) => {
      let key1 = record.subgrupo_titulo
      if (key1 != null) key1 = key1.toLowerCase()
      if (value != null) value = value.toLowerCase()
      if (key1 == null) return false
      return key1.includes(value.toLowerCase())
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          console.log(searchInput.value)
          searchInput.value.focus()
        }, 0)
      }
    },
    sorter: (a, b) => a.subgrupo_titulo > b.subgrupo_titulo,
    sortDirections: ['descend'],
  },
  {
    title: 'Descrição',
    dataIndex: 'descricao',
    key: 'descricao',
    slots: { customRender: 'descricao' },
    onFilter: (value, record) => {
      if (value == 0) {
        return record.subgrupo_detalhado && record.raw == null
      } else if (value == 1) {
        return record.subgrupo_detalhado && record.raw != null
      } else if (value == 2) {
        return (
          !record.subgrupo_detalhado &&
          (record.subgrupo_descricao == null ||
            record.subgrupo_descricao == '') &&
          (record.descricao == null || record.descricao == '')
        )
      } else {
        return true
      }
    },
    filters: [
      {
        text: 'TODOS',
        value: 'null',
      },

      {
        text: 'DETALHES NÃO PREENCHIDOS',
        value: '0',
      },
      {
        text: 'DETALHES PREENCHIDOS',
        value: '1',
      },
      {
        text: 'ARQUIVOS SEM DESCRICAO',
        value: '2',
      },
    ],
  },
  {
    title: 'Data de Publicação',
    dataIndex: 'data_publicacao',
    onFilter: (value, record) => record.data_publicacao.indexOf(value) === 0,
    key: 'data_publicacao',
    filters: [
      {
        text: '2021',
        value: '2021',
      },
      {
        text: '2020',
        value: '2020',
      },
      {
        text: '2019',
        value: '2019',
      },
      {
        text: '2018',
        value: '2018',
      },
      {
        text: '2017',
        value: '2017',
      },
      {
        text: '2016',
        value: '2016',
      },
      {
        text: '2015',
        value: '2015',
      },
      {
        text: '2014',
        value: '2014',
      },
    ],
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    slots: { customRender: 'status' },
    onFilter: (value, record) => {
      if (value == 2) return record.id_subgrupo == 0
      if (value == 1) return record.status == 1
      if (value == 0) return record.status == 0
    },
    key: 'status',
    filters: [
      {
        text: 'Ativo',
        value: '1',
      },
      {
        text: 'Inativo',
        value: '0',
      },
      {
        text: 'Sem grupo',
        value: '2',
      },
    ],
  },
  {
    title: 'Ações',
    key: 'id',
    slots: { customRender: 'action' },
  },
]
