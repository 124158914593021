import moment from 'moment'
export const APP_NAME = 'Dashboard - Transparência'
export const BASE_URL = 'https://transpsync.anc-app.xyz/'
export const API_URL = 'https://transpsync.anc-app.xyz/'
export const ANO = new Date().getFullYear()
export const EMAIL = 'transparência@gmail.com'
export const TIPO = 'Transparência'

export const AUTARQUIA = {
  id: 1,
  nome: 'Transparência',
}

export const TEMA = {
  navbar: {
    textTitleColor: 'text-white ',
    textTitleSize: 'text-2xl',
    bgColor: 'bg-red-500',
    textItemColor: 'text-white',
    textItemHoverColor: 'text-black',
    textItemSize: 'text-xl',
    fixed: true,
    mobileMenuIconColor: 'bg-white',
  },
  footer: {
    bgColor: 'bg-red-600',
    textColor: 'text-white',
    textSize: 'text-base',
  },
  card: {
    primary: {
      bgColor: 'bg-gray-400',
      bgHoverColor: 'bg-gray-400',
    },
  },
}

/**
 *  Atenção
 *  é necessário que a rota abaixo já esteja adicionada no arquivo
 *  /src/router/index.js
 */
export const ROUTES = [
  { name: 'Ajustes', route: '/configuracoes' },
  { name: 'Transparência', route: '/arquivos' },
]

export const openLink = (link) => {
  window.open(link, '_blank')
}

export const getRefValue = (PARAMS, ref) => {
  if (PARAMS == undefined || PARAMS == null) {
    return ''
  }
  const data = PARAMS.find((x) => x.ref == ref)
  if (data == undefined || data == null) return ''
  return data.value
}

export const dateToLocalDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const stringToArray = (code) => {
  const elements = []
  code = code.replace('[{', '{')
  code = code.replace('}]', '}')
  code = code.split('},{')
  if (code.length == 1) {
    elements.push(JSON.parse(code[0]))
  } else {
    for (let i = 0; i < code.length; i++) {
      if (i == 0) {
        code[i] = code[i] + '}'
      } else if (i < code.length - 1) {
        code[i] = '{' + code[i] + '}'
      } else {
        code[i] = '{' + code[i]
      }
      elements.push(JSON.parse(code[i]))
    }
  }
  return elements
}
